import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApicallerService } from 'src/app/services/apicaller.service';
import { LoaderServiceService } from '../../services/loader-service.service';
@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.css'],
})
export class UserloginComponent implements OnInit {
  loginForm: any = UntypedFormGroup;
  intialVal: boolean = true;
  enterotp: any = UntypedFormGroup;
  otpVal: boolean = false;
  default: any = '+91';
  submittedotp: boolean = false;
  submitted = false;
  userOtp: any;
  resendotp = false;
  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private apiCaller: ApicallerService,
    public loaderservice : LoaderServiceService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required, Validators.maxLength]],
      countryCode: ['', [Validators.required]],
      terms: [false, [Validators.requiredTrue]]
    });
    this.loginForm.patchValue({countryCode: this.default});
    this.enterotp = this.formBuilder.group({
      otpone: ['', [Validators.required]],
      otptwo: ['', [Validators.required]],
      otpthree: ['', [Validators.required]],
      otpfour: ['', [Validators.required]],
    });
  }

  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  Validate(event: {
    charCode: number;
    which: number;
    preventDefault: () => void;
  }) {
    var regex = new RegExp('^[0-9-!+@#$%*?]');
    var key = String.fromCharCode(
      event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  submitOtp() {
    this.submittedotp = true;
    if (this.enterotp.invalid) {
      return;
    } else {
      this.userOtp =
        this.enterotp.value.otpone +
        this.enterotp.value.otptwo +
        this.enterotp.value.otpthree +
        this.enterotp.value.otpfour;
      if (this.userOtp !== '') {
        this.apiCaller
          .verifyOtp(this.loginForm.value.countryCode + this.loginForm.value.phonenumber, this.userOtp)
          .subscribe((res: any) => {
            if (res.status == "SUCCESS") {
              localStorage.setItem("login", "user");
              localStorage.setItem("ROLE", "user");
              localStorage.setItem('STATE', 'true');
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("id", res.data.id);
              if(res.data.isProfileUpdated == false){
                localStorage.setItem("id", res.data.id);
                localStorage.setItem("token", res.data.token);
                this.loaderservice.successNotification("OTP Verified Successfully.");
                this.router.navigate(["/ProfileDetails"]);
              } else{
                this.loaderservice.successNotification("OTP Verified Successfully.");
                localStorage.setItem("userInfo", JSON.stringify(res?.data));
                this.router.navigate(["/LayoutComponent/report"]);
              }
            } else {
              this.loaderservice.failNotification("Invalid OTP.");
            }
          });
      }
    }
  }

  get otperror() {
    return this.enterotp.controls;
  }

  get tempData() {
    return this.loginForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.login();
    }
  }

  login() {
    this.apiCaller
      .getOtp(this.loginForm.value.countryCode + this.loginForm.value.phonenumber)
      .subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.intialVal = false;
          this.otpVal = true;
          this.loaderservice.successNotification("OTP is sent to your Mobile number.");
        } else {
          this.loaderservice.failNotification("LogIn Failed.");
      }
    });
  }

  resend(){
    this.resendotp = true;
    if(this.resendotp){
      //this.loaderservice.successNotification("OTP sent. Please check");
      this.login();
    }
  }
}

<div class="container-fluid">
  <div class="row">
    <a
      class="welcome_logo mt-4 ms-3"
      href="#"
      routerLink="/welcome"
      title="Oblingo"
      ><img src="../../assets/images/logo2.png" alt=""
    /></a>
  </div>
  <div class="row loginOrder">
    <div
      class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 hidedata text-center"
    >
      <img class="imgleftlogo" src="../../assets/images/userbg.png" />
    </div>
    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 loginnumber col-xs-12">
      <div class="well">
        <div class="card-body" *ngIf="intialVal">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="my-form">
            <select
              class="countrycde"
              style="padding-left: 20px; top:40px"
              formControlName="countryCode"
              id=""
            >
              <option data-countryCode="GB" value="+44" Selected>
                UK (+44)
              </option>
              <option data-countryCode="US" value="+1">USA (+1)</option>
              <option data-countryCode="IND" value="+91">IND (+91)</option>
            </select>
            <input
              type="text"
              placeholder="Enter Mobile Number"
              (keypress)="Validate($event)"
              maxlength="15"
              class="form-control my-2 p-4"
              formControlName="phonenumber"
              [ngClass]="{ 'is-invalid': submitted && tempData.phonenumber.errors }"
            />
            <div
              *ngIf="submitted && tempData.phonenumber.errors"
              class="invalid-feedback"
            >
              <div *ngIf="tempData.phonenumber.errors.required">
                Mobile number is required
              </div>
            </div>
            <div></div>
            <!--
            Add checkbox with label I agree to receive transactional messaging from Oblingo at the phone number provided above. I understand i will receive 2-4 messages a month based on my usage. data rates may apply, reply STOP to opt out
             -->
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
                formControlName="terms"
                [ngClass]="{ 'is-invalid': submitted && tempData.terms.errors }"
              />
              <label class="form-check-label" for="exampleCheck1">
                I agree to receive transactional messaging from Oblingo at the phone number provided above. I understand I will receive 2-4 messages a month based on my usage. Data rates may apply. Reply STOP to opt out.
              </label>
              <div
                *ngIf="submitted && tempData.terms.errors"
                class="invalid-feedback"
              >
                <div *ngIf="tempData.terms.errors.required">
                  Please accept terms and conditions
                </div>
              </div>
            </div>
            <button type="submit" class="btn1">NEXT</button>
            <!--
            Add privacy and terms and conditions link from my assests/pdfs  Oblingo-Privacy-Policy.pdf
OPT-Terms-Conditions-5.21.docx.pdf
             -->
            <div class="help">
              <p class="forgottext">

                <a href="../../assets/pdfs/Oblingo-Privacy-Policy.pdf" target="_blank"
                  >Privacy Policy</a
                >
                |
                <a href="../../assets/pdfs/OPT-Terms-Conditions-5.21.docx.pdf" target="_blank"
                  >Terms and Conditions</a
                >
              </p>
            </div>

          </form>
        </div>
        <div class="card-body" *ngIf="otpVal">
          <form
            [formGroup]="enterotp"
            (ngSubmit)="submitOtp()"
            class="my-form otpform"
          >
            <div><span class="tittle">Enter OTP</span></div>
            <div>
              <label class="content"
                >We have just sent you 4 digit code via<br />your phone
                number.</label
              >
            </div>

            <div class="one-time-password">
              <input
                type="text"
                class="form-control my-2 p-2"
                formControlName="otpone"
                maxlength="1"
                (keypress)="numberOnly($event)"
                [ngClass]="{
                          'is-invalid': submittedotp && otperror.otpone.errors
                        }"
              />

              <input
                type="text"
                class="form-control my-2 p-2"
                formControlName="otptwo"
                maxlength="1"
                (keypress)="numberOnly($event)"
                [ngClass]="{
                          'is-invalid': submittedotp && otperror.otptwo.errors
                        }"
              />
              <!-- <div *ngIf="submitted && f.otptwo.errors" class="invalid-feedback">
                      <div *ngIf="f.otptwo.errors.required">Enter 4 digit otp</div>
                    </div> -->
              <input
                type="text"
                class="form-control my-2 p-2"
                formControlName="otpthree"
                maxlength="1"
                (keypress)="numberOnly($event)"
                [ngClass]="{
                          'is-invalid': submittedotp && otperror.otpthree.errors
                        }"
              />
              <!-- <div *ngIf="submitted && f.otpthree.errors" class="invalid-feedback">
                      <div *ngIf="f.otpthree.errors.required">Enter 4 digit otp</div>
                    </div> -->
              <input
                type="text"
                class="form-control last my-2 p-2"
                formControlName="otpfour"
                maxlength="1"
                (keypress)="numberOnly($event)"
                [ngClass]="{
                          'is-invalid': submittedotp && otperror.otpfour.errors
                        }"
              />
              <div></div>
              <div
                *ngIf="submittedotp && otperror.otpone.errors"
                class="invalid-feedback otperr"
              >
                <div *ngIf="otperror.otpone.errors.required">
                  Enter 4 digit otp
                </div>
              </div>
            </div>
            <button type="submit" class="btn1">SUBMIT</button>
            <div class="help">
              <p class="resendotp">
                Didn't Receive Code? <a (click)="resend()"> Resend code</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
